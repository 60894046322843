import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const ImageAbout = props => {

    const data = useStaticQuery(graphql`
    query {
      Image: file(relativePath: { eq: "psdreizehn-about.png" }) {
              childImageSharp {
                gatsbyImageData(
                layout: CONSTRAINED
                )
            }
        }
    }
  `)

    return (
        <div className="img-wrapper img-about">
            <GatsbyImage alt={props.title} image={data.Image.childImageSharp.gatsbyImageData} style={{width: '300px', maxWidth: '100%', height: 'auto'}} />
        </div>
    )
}

export default ImageAbout
