import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const LogoShopware = props => {

    const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "technologien/logo-shopware.png" }) {
        childImageSharp {
                gatsbyImageData(
                layout: FIXED
                width: 90
                height: 110
                )
            }
      }
    }
  `)

    return (
        <div className="logo-shopware">
            <a href={props.to} rel="noopener noreferrer" target="_blank">
                <GatsbyImage alt={props.title} image={data.logoImage.childImageSharp.gatsbyImageData} />
            </a>
        </div>
    )
}

export default LogoShopware
