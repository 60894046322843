import React from "react"

import LogoSmall from '../components/images/logo-small'
import LogoMobileSmall from '../components/images/logo-mobile-small'
import LogoShopware from '../components/images/logo-shopware'
import ImageAbout from '../components/images/image-about'
import Layout from "../components/layout"
import Seo from "../components/seo"

import {Link} from "gatsby";
import Typist from 'react-typist';
import { motion } from 'framer-motion'
import AnimationFromRight from '../animations/animation-from-right'
import AnimationFadeIn from '../animations/animation-fade-in'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { faPaintRoller } from '@fortawesome/free-solid-svg-icons'


const ServiceBlock = props => (
        <div className="service">
            <h3 className="service-headline"><FontAwesomeIcon icon={props.icon} /> {props.headline}</h3>
            {props.children}
        </div>
)

const About = () => (
  <Layout>
      <Seo title="about" description="Du erhältst von uns Layout, Programmierung, Support und Wartung für Onlineshops mit Shopware. Starte sofort und lege deinen eigenen Shop an!" />
      <LogoSmall title="ps:dreizehn GmbH"  />
      <LogoMobileSmall title="ps:dreizehn GmbH"  />
      <motion.div variants={AnimationFadeIn} transition="spring" initial="hidden" animate="visible" exit="exit" className="main--left">
        <h1 className="subpage--headline">about</h1>
          <div className="content-wrapper">
              <div className="content">
                  <h2>Shopware Agentur <Typist>für deinen Onlineshop.</Typist></h2>
                  <p>
                      Wir helfen dir gerne beim Erstellen oder Weiterentwickeln deines Onlineshops. Angesiedelt ist unsere kleine Shopware Agentur in Limbach-Oberfrohna in Sachsen
                      (Nähe Chemnitz, Leipzig, Dresden) - wir agieren aber natürlich deutschlandweit.
                  </p>
                  <p>
                      Mit unserer langjährigen Erfahrung als ehemalige Dev-Abteilung im Fulfillment bieten wir dir neben Expertise in Grafik, Fotografie, Entwicklung und vielen weiteren Kompetenzen rund um Shopware
                      auch das Verständnis und Know-How für die Prozesse um den Shop. Wir sind als Agentur im grafischen und technischen Bereich angesiedelt - d.h. wir konzipieren, entwickeln und warten
                      dir deinen Shop, kümmern uns auch mit um die Auswahl deines Hosting-Dienstleisters und supporten hier auch in der Kommunikation.
                  </p>
                  <p>
                      Von uns bekommst du immer genau die Leistungen an die Hand, die wir auch beherrschen ;-). Wir haben aber auch für weitere Anforderungen an deinen Onlineshop ein Netzwerk an starken Partnern im E-Commerce, die dich auch beim Kundenservice, Logistik
                      oder Online-Marketing unterstützen können. Und auch wir wachsen stetig weiter.
                  </p>
                  <p>
                      Also dann: <Link to="/kontakt/">Meld dich einfach bei uns</Link> oder starte doch schon jetzt deinen ersten <a href="https://www.shopware.com/de?partner=0f7c7b23-0963-4730-b91c-f013c323798b#onlineshop-erstellen" target="_blank" rel="noreferrer">Onlineshop mit Shopware</a>.
                      Wir unterstützen dich gerne dabei.
                  </p>
                  <ImageAbout title="ps:dreizehn Büros" />
              </div>
          </div>
      </motion.div>
      <motion.div variants={AnimationFromRight} transition="spring" initial="hidden" animate="visible" exit="exit" className="main--right main--black">
          <div className="content-wrapper">
            <div className="content content-service">
                <h2>Unsere Leistungen</h2>

                <ServiceBlock headline="Development" icon={faCode}>
                    <ul>
                        <li>Wir arbeiten mit Shopware</li>
                        <li>Entwicklung von individuellen Templates oder Plugins für Shopware</li>
                        <li>Technisches und strukturelles SEO</li>
                    </ul>
                </ServiceBlock>

                <ServiceBlock headline="Support &amp; Wartung" icon={faCog}>
                    <ul>
                        <li>Beratung und Auswahl des Hostingdienstleisters</li>
                        <li>Installation und Einrichtung deiner Shopware-Umgebung</li>
                        <li>Migration deines Onlineshops auf Shopware 6</li>
                        <li>Beratung und Support in der Betreuung des Shops</li>
                        <li>Schulungen</li>
                        <li>Dokumentationen</li>
                        <li>Wartungsservice</li>
                    </ul>
                </ServiceBlock>

                <ServiceBlock headline="Grafik &amp; Design" icon={faPaintRoller}>
                    <ul>
                        <li>Konzept</li>
                        <li>Corporate Design/Logo-Erstellung, Redesigns</li>
                        <li>Banner- und Teaser-Erstellung</li>
                        <li>UX-Design</li>
                        <li>Produktfotografie</li>
                    </ul>
                </ServiceBlock>

                <LogoShopware to='https://www.shopware.com' title='Shopware Bronze Partner'/>

            </div>
          </div>
      </motion.div>
  </Layout>
)

export default About
