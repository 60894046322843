import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Logo = props => {

    const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "psdreizehn-logo-weiss-small.png" }) {
        childImageSharp {
                gatsbyImageData(
                layout: FIXED
                width: 90
                )
            }
      }
    }
  `)

    return (
        <div className="logo logo-wrapper">
            <Link to="/">
                <GatsbyImage alt={props.title} image={data.logoImage.childImageSharp.gatsbyImageData} />
            </Link>
        </div>
    )
}

export default Logo
