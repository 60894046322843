const AnimationFromRight = {
    hidden: { left: '100%', opacity: 0 },
    visible: {
        left: '50%',
        opacity: 1,
    },
    exit: { left: '100%', opacity: 0 },
    srping: {
        type: "spring",
        damping: 10,
        stiffness: 200
    }
}

export default AnimationFromRight